import React from 'react';
import cn from 'classnames';

import { Container, Title, Grid, GridItem, Icon, Markup } from 'landing/ui';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './CostOptimization.module.scss';

export default function CostOptimization({ title, list, alertText }) {
  return (
    <>
      <Container id="offer">
        <div className={styles.wrapper}>
          <Title tag="h3" size="h4" className={styles.title}>
            {title}
          </Title>
          <Grid
            xsm={{ cols: 1 }}
            sm={{ cols: 1 }}
            md={{ cols: 2 }}
            lg={{ cols: 2 }}
            xl={{ cols: 2 }}
            fullHd={{ cols: 2 }}
          >
            <GridItem>
              <div className={styles.item}>
                <Title tag="h3" size="h4" className={styles.itemTitle}>
                  <b>Ваши клиенты достойны лучших продуктов!</b> Используйте все преимущества
                  технологий искусственного интеллекта.
                </Title>
                {list.map(el => (
                  <div key={el.title} className={styles.listItem}>
                    <div className={styles.icon}>
                      <Icon name={el.icon} />
                    </div>
                    <p className={styles.listItemTitle}>{el.title}</p>
                  </div>
                ))}
              </div>
            </GridItem>
            <GridItem customClassName={styles.gridItem}>
              <div className={cn(styles.item, styles.itemImage)}>
                <StaticImage
                  src={
                    'https://storage.yandexcloud.net/dev.astonsite.s3backet/landings/astonlab/value-proposition/desktop.jpg'
                  }
                  alt="Ценностное предложение"
                />
              </div>
            </GridItem>
          </Grid>
          <div className={styles.alert}>
            <Markup>{alertText}</Markup>
          </div>
        </div>
      </Container>
    </>
  );
}
