// extracted by mini-css-extract-plugin
export var alert = "CostOptimization-module--alert--501db";
export var box = "CostOptimization-module--box--6a5de";
export var button = "CostOptimization-module--button--243d4";
export var count = "CostOptimization-module--count--a67ff";
export var description = "CostOptimization-module--description--e9b79";
export var descriptionFirst = "CostOptimization-module--descriptionFirst--39903";
export var gridItem = "CostOptimization-module--gridItem--64f96";
export var icon = "CostOptimization-module--icon--357e3";
export var item = "CostOptimization-module--item--8299f";
export var itemImage = "CostOptimization-module--itemImage--f0494";
export var itemTitle = "CostOptimization-module--itemTitle--4f23c";
export var link = "CostOptimization-module--link--83a62";
export var linkWrapper = "CostOptimization-module--linkWrapper--cb9f9";
export var list = "CostOptimization-module--list--583bc";
export var listItem = "CostOptimization-module--listItem--56628";
export var listItemTitle = "CostOptimization-module--listItemTitle--709bb";
export var subtitleItem = "CostOptimization-module--subtitleItem--ed094";
export var title = "CostOptimization-module--title--19322";
export var titleSecond = "CostOptimization-module--titleSecond--971aa";
export var wrapper = "CostOptimization-module--wrapper--3c9e5";