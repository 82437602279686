import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Container, Title } from 'landing/ui';

import * as styles from './JOINTMLWithImage.module.scss';

export default function JOINTMLWithImage({
  title,
  imageSrc,
  mobileSrc = '',
  altImage,
  background = 'white',
  description,
  theme,
}) {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 425) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Container id="about" background={background}>
        <div className={styles.wrapper}>
          <Title tag="h3" size="h4" className={styles.title}>
            {title}
          </Title>
          {description && <div className={styles.description}>{description}</div>}
          <div className={cn(styles.image, Boolean(theme) && styles[theme])}>
            {mobileSrc && isMobile ? (
              <img src={mobileSrc} alt={altImage} />
            ) : (
              <img src={imageSrc} alt={altImage} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
