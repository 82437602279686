import React from 'react';

import { Head, Footer, Header } from 'landing/components';
import Intro from 'components/Intro';
import WhatWeDo from 'components/WhatWeDo';
import CostOptimization from 'components/CostOptimization';
import JOINTMLWithImage from 'components/JOINTMLWithImage';
import CookiesPolicy from 'components/CookiesPolicy';

import data from './data.json';

function Index() {
  return (
    <>
      <Head {...data.head} />
      <Header isHeaderFixed {...data.header} />
      <Intro {...data.intro} page="internal" />
      <CostOptimization {...data.costOptimization} />
      <WhatWeDo {...data.forWhomOurService} />
      <JOINTMLWithImage {...data.trainingJOINTML} theme="withoutCentering" />
      <WhatWeDo {...data.innovations} />
      <JOINTMLWithImage {...data.collaborationModelsJOINTML} />
      <JOINTMLWithImage {...data.compoundJOINTML} />
      <WhatWeDo {...data.competitiveAdvantages} />
      <Footer {...data.footer} />
      <CookiesPolicy />
    </>
  );
}
export default Index;
